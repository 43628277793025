<template>
  <v-app>
    <v-container data-app>
      <v-card>
        <v-card-title>
          Клиенты
          <v-btn @click="openNewClientModal" class="ml-4 align-self-end">+ Добавить</v-btn>
          <!--          v-if="$store.state.user.city.id==1"-->
          <NewClient ref="newClientModal"/>
        </v-card-title>
        <v-card-text>
          <div class="client-filter-row">
            <div class="client-filter-row__label">
              <strong>Клиент:</strong>
            </div>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>Телефон или имя</label>
                <v-text-field
                    autocompete="off"
                    @change="filtersUpdated"
                    v-model="filters.client_f.query"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Банк. карта</label>
                <v-select
                    @change="filtersUpdated"
                    v-model="filters.client_f.defaultCard"
                    item-text="name"
                    item-value="value"
                    :items="[{name:'Неважно', value: ''}, {name:'Привязана', value: '1'}, {name:'Не привязана', value: '0'}]"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Уборок >=</label>
                <v-text-field @change="filtersUpdated" outlined v-model="filters.client_f.ordersCount"/>
              </div>
              <div class="client-filter-row__item">
                <label>Бонусов >=</label>
                <v-text-field @change="filtersUpdated" outlined v-model="filters.client_f.bonus"/>
              </div>
              <div class="client-filter-row__item">
                <label>Приложение</label>
                <v-select
                    v-model="filters.client_f.withApp"
                    @change="filtersUpdated"
                    item-text="name"
                    hide-details
                    item-value="value"
                    :items="[{name: 'Неважно', value: 0},{name: 'Есть', value: 1},{name: 'Нет', value: 2},]"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Пол</label>
                <v-select
                    v-model="filters.client_f.gender"
                    @change="filtersUpdated"
                    multiple
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="[{name: 'Мужской', value: 1},{name: 'Женский', value: 2},{name: 'Не определено', value: 0}]"
                    outlined
                ></v-select>
              </div>
            </div>
          </div>
          <div class="client-filter-row">
            <div class="client-filter-row__label">
              <strong>Адрес:</strong>
            </div>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>Поиск по улице</label>
                <v-text-field
                    autocompete="re-new-password"
                    @change="filtersUpdated"
                    v-model="filters.address.street"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Кол-во комнат</label>
                <v-select
                    @change="filtersUpdated"
                    v-model="filters.address.flattype"
                    multiple
                    :items="['1','2','3','4','5']"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Регион</label>
                <v-select
                    v-model="filters.address.region"
                    @change="filtersUpdated"
                    multiple
                    :items="[{n_id:0, title:'Любой'}].concat($store.state.user.regions)"
                    item-text="title"
                    item-value="n_id"
                    outlined/>
              </div>
            </div>
          </div>
          <div class="client-filter-row">
            <div class="client-filter-row__label">
              <strong>Первый заказ:</strong>
            </div>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>Дата уборки</label>
                <v-menu
                    ref="menu"
                    transition="scale-transition"
                    left
                    :close-on-content-click="false"
                    bottom
                    nudge-bottom="35"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        :value="$root.outputRange(filters.order.first_order)"
                        readonly
                        v-bind="attrs"
                        @change="filtersUpdated"
                        style="width: 100%"
                        v-on="on"
                    />
                    <v-btn
                        icon
                        @click.stop="filters.order.first_order =[]"
                        color="grey"
                        :style="filters.order.first_order && filters.order.first_order.length ? '' : 'display: none'"
                        style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                      v-model="filters.order.first_order"
                      no-title
                      scrollable
                      range
                      first-day-of-week="1"
                      locale="ru"
                  />
                </v-menu>
              </div>
            </div>
          </div>
          <div class="client-filter-row">
            <div class="client-filter-row__label">
              <strong>Заказ:</strong>
            </div>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>Дата уборки</label>
                <v-menu
                    ref="menu"
                    transition="scale-transition"
                    left
                    :close-on-content-click="false"
                    bottom
                    nudge-bottom="35"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        :value="$root.outputRange(filters.order.dateclean)"
                        readonly
                        @change="filtersUpdated"
                        v-bind="attrs"
                        style="width: 100%"
                        v-on="on"
                    />
                    <v-btn
                        icon
                        @click.stop="filters.order.dateclean =[]"
                        color="grey"
                        :style="filters.order.dateclean && filters.order.dateclean.length ? '' : 'display: none'"
                        style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                      v-model="filters.order.dateclean"
                      no-title
                      scrollable
                      range
                      first-day-of-week="1"
                      locale="ru"
                  />
                </v-menu>
              </div>
              <div class="client-filter-row__item">
                <label>Дата создания</label>
                <v-menu
                    ref="menu"
                    transition="scale-transition"
                    left
                    :close-on-content-click="false"
                    bottom
                    nudge-bottom="35"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        :value="$root.outputRange(filters.order.datecreate)"
                        readonly
                        @change="filtersUpdated"
                        v-bind="attrs"
                        style="width: 100%"
                        v-on="on"
                    />
                    <v-btn
                        icon
                        @click.stop="filters.order.datecreate =[]"
                        color="grey"
                        :style="filters.order.datecreate && filters.order.datecreate.length ? '' : 'display: none'"
                        style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                      v-model="filters.order.datecreate"
                      no-title
                      scrollable
                      range
                      first-day-of-week="1"
                      locale="ru"
                  />
                </v-menu>
              </div>
              <div class="client-filter-row__item">
                <label>Тип уборки</label>
                <v-select
                    v-model="filters.order.type"
                    @change="filtersUpdated"
                    item-text="name"
                    multiple
                    item-value="value"
                    :items="orderTypes"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Промо</label>
                <v-text-field @change="filtersUpdated" v-model="filters.order.promo" outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Доп услуги</label>
                <v-select
                    v-model="filters.order.services"
                    @change="filtersUpdated"
                    item-text="name"
                    multiple
                    item-value="value"
                    :items="[{name: 'Мытье окон', value: 'windowNum'}]"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Есть промо</label>
                <v-select
                    v-model="filters.order.withPromo"
                    @change="filtersUpdated"
                    item-text="name"
                    item-value="value"
                    :items="[{name: 'Неважно', value: 0},{name: 'Есть', value: 1},{name: 'Нет', value: 2},]"
                    outlined/>
              </div>
            </div>
          </div>
          <div class="client-filter-row">
            <div class="client-filter-row__label"/>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>Есть скидка</label>
                <v-select
                    v-model="filters.order.withDiscount"
                    @change="filtersUpdated"
                    item-text="name"
                    item-value="value"
                    :items="[{name: 'Неважно', value: 0},{name: 'Есть', value: 1},{name: 'Нет', value: 2},]"
                    outlined/>
              </div>
              <div class="client-filter-row__item">
                <label>Стоимость уборки</label>
                <v-row>
                  <v-col class="pa-0 pr-1">
                    <v-text-field @change="filtersUpdated" outlined placeholder="От" v-model="filters.order.total_from"/>
                  </v-col>
                  <v-col class="pa-0">
                    <v-text-field @change="filtersUpdated" outlined placeholder="До" v-model="filters.order.total_to"/>
                  </v-col>
                </v-row>
              </div>
              <div class="client-filter-row__item">
                <label>Оценка</label>
                <v-select
                    v-model="filters.order.feedback"
                    @change="filtersUpdated"
                    multiple
                    :items="feedbackFilterItems"
                    outlined
                    item-text="text"
                    item-value="value"
                />
              </div>
              <div class="client-filter-row__item">
                <label>Способ оформления</label>
                <v-select
                    v-model="filters.order.createdIn"
                    @change="filtersUpdated"
                    multiple
                    :items="$store.state.toSelectItems({'4_':'Android App','5_':'IOS App', '6_':'Сайт не Safari','7_':'Сайт Safari', '9_':'По подписке',  '8_':'CRM',  '1_':'Старый сайт',  '0_':'Старая црм'})"
                    outlined
                />
              </div>
              <div class="client-filter-row__item">
                <label>Удаленные</label>
                <v-select
                    v-model="filters.order.deleted"
                    @change="filtersUpdated"
                    multiple
                    :items="$store.state.toSelectItems({'sent_to':'Отправлены в другой город','client':'Отменен клиентом', 'manager':'Отменен менеджером'})"
                    outlined
                />
              </div>
            </div>
          </div>
          <div class="client-filter-row">
            <div class="client-filter-row__label">
              <strong>UTM:</strong>
            </div>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>utm_source</label>
                <v-text-field @change="filtersUpdated" outlined placeholder="yandex" v-model="filters.order.utm_source"/>
              </div>
              <div class="client-filter-row__item">
                <label>utm_medium</label>
                <v-text-field @change="filtersUpdated" outlined placeholder="cpc" v-model="filters.order.utm_medium"/>
              </div>
              <div class="client-filter-row__item">
                <label>utm_campaign</label>
                <v-text-field @change="filtersUpdated" outlined placeholder="ПОИСК | клининг | Зеленоград"
                              v-model="filters.order.utm_campaign"/>
              </div>
            </div>
          </div>
          <div class="client-filter-row">
            <div class="client-filter-row__label">
              <strong>Не было заказов:</strong>
            </div>
            <div class="client-filter-row-items">
              <div class="client-filter-row__item">
                <label>Дней</label>
                <v-text-field @change="filtersUpdated" outlined v-model="filters.order.no_orders"/>
              </div>
            </div>
          </div>
          <v-row>
            <v-col>
              <v-btn :loading="loading_table" @click="getClients()" class="mt-4 mr-2">Поиск</v-btn>
              <v-btn @click="filters = {...defaultFilters};getClients();" class="v-btn-danger mt-4">Очистить фильтры
              </v-btn>
              <v-btn
                  v-if="$store.state.user.rights.includes('downloadClients') || $store.state.user.login=='maria2' && showRevenueCost"
                  class="ml-2 mt-4 v-btn-transp" :href="downloadURL">
                Выгрузить
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn v-if="$store.state.user.rights.includes('sendPushToClients')"
                     class="ml-2 mt-4 v-btn-transp" @click="$refs.sendPush.open(prepareParams(), count)">
                Отправить пуши
                <v-icon>mdi-bell-outline</v-icon>
              </v-btn>
              <v-btn :class="{'v-btn-transp':!showChart}"
                     class="ml-2 mt-4" @click="showChart=!showChart;getClients();">
                График
                <v-icon>mdi-chart</v-icon>
              </v-btn>
              <v-btn v-if="$store.state.user.city.id==1" :class="{'v-btn-transp':!showRevenueCost}"
                     class="ml-2 mt-4" @click="showRevenueCost=!showRevenueCost;getClients();">
                Расходы/Доходы
                <v-icon>mdi-chart</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-checkbox v-if="showRevenueCost" @change="getClients()" label="По всем заказам клиентов" :true-value="1"
                      :false-value="0" v-model="revenueTableByOrders"/>
          <RevenueCost :table="revenueTable" v-if="showRevenueCost"></RevenueCost>
          <template v-if="showChart">
            <LineChartGenerator
                v-if="filters.order.dateclean[0] && filters.order.dateclean[1]"
                :chart-options="chartOptions"
                :chart-data="barChartData"
                chart-id="bar-chart"
                ref="chart"
                :width="400"
                :height="300"
            />
            <v-row v-else>
              <v-alert
                  style="width: 100%;"
                  dense
                  border="left"
                  type="error"
              >
                Чтобы отобразился график, выберите период в поле "Дата уборки" и нажмите "Поиск"
              </v-alert>
            </v-row>
          </template>
          <v-data-table
              v-else
              :headers="headers"
              :items="clients"
              item-key="n_id"
              @update:page="setPage($event)"
              :loading="loading_table"
              no-data-text="Клиентов нет"
              :footer-props="{'items-per-page-options':[20],showFirstLastPage: true,showCurrentPage: true}"
              loading-text="Загрузка..."
              :server-items-length="count"
              :page="page"
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr v-if="item.hasOwnProperty('name')">
                <td>{{ (page - 1) * 20 + index + 1 }}</td>
                <td><a @click="$eventBus.$emit('openClient', item.n_id)">{{ item.name }}</a></td>
                <td style="white-space: nowrap" v-on:click.stop><a :href="'tel: '+item.phone">{{ item.phone }}</a></td>
                <td>
                  <span v-if="item.stats.likes > 0" style="color: green">{{ item.stats.likes }}</span>
                  /
                  <span style="color: red;margin-right: 5px;">{{
                      item.stats.dislikes
                    }}</span>
                </td>
                <td>
                  <a v-if="item.order" :href="`#/orders/${item.order.n_id}`" target="_blank" role="link">
                    {{ $root.dateToRus(item.order.datetimecleanObj.date, 0, 1) }}
                    {{ item.order.datetimecleanObj.time }}
                  </a>
                </td>
                <td>
                  <a v-if="item.order" :href="`#/orders/${item.order.n_id}`" target="_blank" role="link">
                    {{ item.order.address.street }} {{ item.order.address.house }}
                  </a>
                </td>
                <td v-if="item.order">{{ item.order.promo }}</td>
                <td v-if="item.order" style="white-space: nowrap">{{ $root.printCost(item.order.total) }}</td>
                <td v-if="item.order">
                  <a class="cleaner" v-for="cleaner in item.order.cleaners" :key="cleaner.n_id"
                     v-on:click.stop="$eventBus.$emit('openCleaner', cleaner.n_id)" style="cursor: pointer;">
                    {{ $root.cleanerInitials(cleaner.name) }}
                  </a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <SendPush ref="sendPush"/>
  </v-app>
</template>

<script>

import SendPush from "./SendPush";
import {Line as LineChartGenerator} from "vue-chartjs/legacy"
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import NewClient from "../../containers/Modals/NewClient";
import RevenueCost from "../../components/RevenueCost";
import Date from "../../components/Date";
import DatePeriod from "../../components/DatePeriod";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

export default {
  name: "Clients",
  components: {DatePeriod, Date, RevenueCost, NewClient, SendPush, LineChartGenerator},
  data() {
    let defaultFilters = {
      client_f: {defaultCard: '', ordersCount: '', gender: []},
      address: {},
      order: {dateclean: [], datecreate: [], first_order: [], withPromo: 0, withDiscount: 0, services: []},
      // layout: {showChart: false,},
    };
    let filters = {...defaultFilters}
    // filters.order.dateclean  = [this.$moment().startOf('isoWeek').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
    let orderTypes = [];
    for (let i in this.$store.state.cleanTypes) {
      orderTypes.push({name: this.$store.state.cleanTypes[i], value: i});
    }
    return {
      loading: false,
      revenueTable: {},
      showChart: false,
      showRevenueCost: false,
      revenueTableByOrders: 0,
      loading_table: true,
      count: 0,
      page: 1,
      filters,
      orderTypes,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: c => false,
            font: {
              weight: 'bold'
            },
            formatter: Math.round
          }
        },
      },
      defaultFilters: {...defaultFilters},
      headers: [
        {value: '', text: '№'},
        {value: 'name', text: 'Имя'},
        {value: 'phone', text: 'Телефон'},
        {value: 'stats', text: 'Оценки'},
        {value: 'order.datetimeclean', text: 'Дата уборки'},
        {value: 'address.street', text: 'Адрес'},
        {value: 'order.promo', text: 'Промокод'},
        {value: 'order.total', text: 'Цена'},
        {value: '', text: 'Клинеры'},
        // {value: 'order', text: 'Заказ'},
        // {value: '', text: 'Оценки'},
        // {value: '', text: 'Оценка уборки'},
        // {value: '', text: 'Клиент'},
        // {value: '', text: 'Действие'},
      ],
      clients: [],
      searchPhones: null,
      client_id: null,
      phones: [],
      datasets: [],
      barChartData: {},
      stats: null,
      query: null,
      feedbackFilterItems: [
        {value: '0', text: 'Без оценки'},
        {value: '1', text: '1'},
        {value: '2', text: '2'},
        {value: '3', text: '3'},
        {value: '4', text: '4'},
        {value: '5', text: '5'}
      ]
    }

  },
  methods: {
    setPage(page) {
      this.page = page;
      this.getClients();
    },
    prepareParams() {
      let params = JSON.parse(JSON.stringify(this.filters));
      params.page = this.page;
      params.chart = this.showChart ? 1 : 0;
      params.revenueTable = this.showRevenueCost ? 1 : 0;
      params.revenueTableByOrders = this.revenueTableByOrders;
      let moreOrEq = ['ordersCount', 'bonus'];
      moreOrEq.forEach((field) => {
        if (+params.client_f[field]) params.client_f[field] = '>=' + (+params.client_f[field]);
        else params.client_f[field] = '';
      })
      return params;
    },
    filtersUpdated() {
      if (!this.showChart) return;
      if (this.filters.order.dateclean[0] && this.filters.order.dateclean[1] || !this.filters.order.dateclean[0] && !this.filters.order.dateclean[1]) {
        this.getClients();
      }
    },
    renderbarChartData() {
      let sameDatasetLength = this.datasets.length === Object.keys(this.stats.datasets).length;
      this.datasets = [];
      if (!this.stats) return {};
      let i = 0;
      for (let label in this.stats.datasets) {
        let info = label.split('|');
        let hidden = !info[0].includes('Исполнено');
        if (sameDatasetLength) {
          hidden = !this.$refs.chart.getCurrentChart().isDatasetVisible(i);
        }
        this.datasets.push({
          label: info[0],
          backgroundColor: info[1],
          borderColor: info[1],
          data: this.stats.datasets[label],
          datalabels: {
            align: 'end',
            anchor: 'start'
          },
          tension: 0.2,
          hidden: hidden,
        })
        i++;
      }
      return {
        labels: this.stats.labels.map(date => {
          return `${this.$moment(date).format('ddd, DD.MM')}`
        }),
        datasets: this.datasets,
      };
    },
    getClients() {
      // return;
      try {
        this.$router.push({query: {}});
        let f = {...this.filters};
        this.$router.push({query: f})
      } catch (e) {
        console.log('router query exception')
      }
      this.loading_table = true;
      this.clients = [];
      this.stats = null;

      this.$store.state.server.request('client/get', this.prepareParams(), (data) => {
        this.clients = data.response;
        if (parseInt(data.count)) this.count = parseInt(data.count);
        if (data.revenueTable) this.revenueTable = data.revenueTable;
        if (data.stats) {
          this.stats = data.stats;
          this.barChartData = this.renderbarChartData();
        }
        // if (this.clients.length === 1 && !this.clients[0].hasOwnProperty('name')) {
        //   this.$eventBus.$emit('openClient', this.clients[0].n_id);
        // }
        this.loading_table = false;
      }, () => {
        this.loading_table = false
      })
    },
    openNewClientModal() {
      this.$refs.newClientModal.open();
    }
  },
  computed: {
    downloadURL() {
      let params = this.prepareParams();
      params.csv = 1;
      return this.$store.state.server.genUrl('client/get', params);
    },
  },
  mounted() {
    for (let i in this.$route.query) if (['order', 'client_f', 'address'].includes(i)) this.filters[i] = {...this.$route.query[i]};
    this.getClients()
  },
}
</script>
<style scoped lang="scss">
.client-filter-row {
  margin-top: 16px;
  display: grid;
  align-items: flex-end;
  grid-gap: 10px;
  grid-template-columns:100px 1fr;
  &-items {
    display: grid;
    align-items: flex-end;
    grid-gap: 10px;
    grid-template-columns:repeat(auto-fit, minmax(100px, 125px));
  }
}

@media screen and (max-width: 1024px) {
  .client-filter-row {
    display: block;
  }
}
</style>
