<template>
  <v-dialog data-app v-model="opened" :retain-focus="false" max-width="600px">
    <v-container style="padding: 0; min-height: auto">
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card style="margin-bottom: 0">
        <v-card-title>Отправить Push уведомления выбранным клиентам</v-card-title>
        <v-card-text>
          <p>Уведомление будет отправлено {{ count }} клиентам</p>
          <v-textarea label="Текст" v-model="text"/>
          <v-checkbox class="mt-2"
                      label="Отправить пуши клиентам по всем городам с выбранными фильтрами (Отправляется только по текущей стране)"
                      v-model="params.all_cities"/>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn @click="send" :loading="loading_save">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: "SendPush",
  data() {
    return {
      opened: false,
      loading_save: false,
      params: {},
      text: '',
      count: 0,
      timeout: 0,
    }
  },
  methods: {
    open(params, count) {
      this.params = params;
      this.count = count;
      this.opened = true;
      this.text = '';
      if (this.timeout) clearTimeout(this.timeout);
      this.params.all_cities = 0;
    },
    send() {
      this.loading_save = true
      this.params.text = this.text;
      this.timeout = setTimeout(() => {
        console.log('timeout');
        this.loading_save = false
        this.$root.notify('Уведомления отправлены', 'success')
        this.opened = false
      }, 2000);
      this.$store.state.server.request('client/sendPush', this.params, (data) => {
        console.log('success')
        this.loading_save = false
        this.$root.notify('Уведомления отправлены', 'success')
        this.opened = false
        clearTimeout(this.timeout);
      }, (data) => {
        console.log('error')
        this.loading_save = false
        clearTimeout(this.timeout);
        if (data && data.error) this.$root.notify(data.error, 'error')
        else {
          this.$root.notify('Уведомления отправлены', 'success')
          this.opened = false
        }
      })
    },
  },
}
</script>
