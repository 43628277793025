<template>
  <v-list-item>
    <v-list-item-content>{{title}}</v-list-item-content>
    <v-list-item-content class="align-end" style="display: inline-block">
      <SumChip :value="sum"/>
      <span style="color: #3399ff;" class="ml-2">{{$root.printCost(sum/orders_count)}}</span></v-list-item-content>
  </v-list-item>
</template>

<script>
import SumChip from "./SumChip";
export default {
  name: "RevenueCostRow",
  components: {SumChip},
  props: ['title', 'sum', 'orders_count'],
}
</script>
