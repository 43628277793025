<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-card-title>Прямые</v-card-title>
          <div class="text-h6">Стоимость полного заказа</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Полная стоимость заказов без допов" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.cleaningCost"/>
            <RevenueCostRow title="Доп услуги" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.additionalCost"/>
            <RevenueCostRow title="Переуборка" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.reorderCost"/>
          </v-list>
          <div class="text-h6">Лояльность</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Скидки" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.discount"/>
            <RevenueCostRow title="Бонусы" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.bonus"/>
          </v-list>
          <div class="text-h6">Эквайринг</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Оплата заказа" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.acquiringCleaning"/>
            <RevenueCostRow title="Чаевые" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.acquiringTips"/>
            <RevenueCostRow title="Переуборка" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.acquiringReorder"/>
          </v-list>
          <div class="text-h6">ЗП клинера</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="ЗП клинера" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.cleanerSalary"/>
            <RevenueCostRow title="Штрафы клинера" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.cleanerFines"/>
            <RevenueCostRow title="Чаевые получено" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.tips"/>
            <RevenueCostRow title="Чаевые выдано клинерам" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.cleanerTips"/>
            <RevenueCostRow title="ЗП переуборки" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.reorderCleanerSalary"/>
            <RevenueCostRow title="Страховой фонд" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.insuranceFond"/>
            <RevenueCostRow title="Такси получено от клиента" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.taxiPlus"/>
            <RevenueCostRow title="Такси потрачено" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.taxiMinus"/>
          </v-list>
          <div class="text-h6">Итого</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Итого" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.total"/>
            <RevenueCostRow title="Доход" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.totalPlus"/>
            <RevenueCostRow title="Расход" :orders_count="renderedTable.orders_count" :sum="renderedTable.direct.totalMinus"/>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-card-title>Непрямые</v-card-title>
          <div class="text-h6">Привлечение</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Авито" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Авито"/>
            <RevenueCostRow title="Гугл" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Гугл"/>
            <RevenueCostRow title="Яндекс" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Яндекс"/>
            <RevenueCostRow title="Реклама" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Реклама"/>
            <RevenueCostRow title="Сео" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Сео"/>
            <RevenueCostRow title="Таргет" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Таргет"/>
          </v-list>
          <div class="text-h6">Развитие бизнеса</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Закупки оборудования" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect['Закупки оборудования']"/>
            <RevenueCostRow title="Корп расходы" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect['Корп расходы']"/>
            <RevenueCostRow title="Телеком" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect['Телеком']"/>
            <RevenueCostRow title="Офис" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Офис"/>
            <RevenueCostRow title="Зарплата офис" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect['Зарплаты офис']"/>
            <RevenueCostRow title="Взнос учредителя" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect['Взнос учредителя']"/>
            <RevenueCostRow title="Прочее" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Прочее"/>
          </v-list>
          <div class="text-h6">Коммуникация</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Манго" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Манго"/>
            <RevenueCostRow title="СМС сервис" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect['СМС сервис']"/>
          </v-list>
          <div class="text-h6">Другое</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Премии клинерам" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.cleanersAwards"/>
            <RevenueCostRow title="Бухгалтерия" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Бухгалтерия"/>
            <RevenueCostRow title="Ущерб" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.Ущерб"/>
          </v-list>
          <div class="text-h6">Итого</div>
          <v-divider></v-divider>
          <v-list dense>
            <RevenueCostRow title="Итого" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.total"/>
            <RevenueCostRow title="Доход" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.totalPlus"/>
            <RevenueCostRow title="Расход" :orders_count="renderedTable.orders_count" :sum="renderedTable.indirect.totalMinus"/>
          </v-list>
        </v-col>
      </v-row>
      <div class="text-h6">Итого</div>
      <v-divider></v-divider>
      <v-list dense>
        <RevenueCostRow title="Итого" :orders_count="renderedTable.orders_count" :sum="renderedTable.total"/>
        <RevenueCostRow title="Доход" :orders_count="renderedTable.orders_count" :sum="renderedTable.totalPlus"/>
        <RevenueCostRow title="Расход" :orders_count="renderedTable.orders_count" :sum="renderedTable.totalMinus"/>
        <v-list-item>
          <v-list-item-content>Заказов</v-list-item-content>
          <v-list-item-content class="align-end">{{renderedTable.orders_count}} шт</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import RevenueCostRow from "./RevenueCostRow";
let defaultTable = {direct: {}, indirect: {}, orders_count: 0};
export default {
  name: "RevenueCost",
  components: {RevenueCostRow},
  props: {
    table: {default: defaultTable}
  },
  data() {
    return {
      renderedTable: defaultTable,
      sections: {
        direct: [
          {
            title: 'Привлечение',
            rows: {cleaningCost: 'Полная стоимость заказов без допов'}
          }
        ],
        indirect: [
          {
            title: 'Привлечение',
            rows: {}
          }
        ]
      }
    }
  },
  watch: {
    table() {
      this.renderedTable = this.table && this.table.direct ? this.table : defaultTable;
    }
  }
}
</script>
