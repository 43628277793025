<template>
  <v-dialog data-app v-model="opened" max-width="400">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Добавление клиента</v-card-title>
      <v-card-text>
        <v-form ref="form"
                lazy-validation>
          <v-row class="row-d-block">
            <v-subheader>Имя</v-subheader>
            <v-text-field
                v-model="client.name"
                outlined/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Телефон</v-subheader>
            <v-text-field
                v-model="client.phone"
                outlined
                type="tel"
                v-mask="$store.state.setMask(client.phone)"/>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Комментарий</v-subheader>
            <v-textarea v-model="client.text" outlined rows="3"/>
          </v-row>
<!--          <v-row class="row-d-block">-->
<!--            <v-subheader>Источник</v-subheader>-->
<!--            <v-select-->
<!--                v-model="client.lead_source"-->
<!--                outlined-->
<!--                :items="$store.state.clientSources"-->
<!--                :rules="[v=>!!v || 'Укажите источник']"-->
<!--                validate-on-blur-->
<!--            />-->
<!--          </v-row>-->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading_save" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewClient",
  data() {
    return {
      opened: false,
      loading_save: false,
      client: {},
    }
  },
  methods: {
    open() {
      this.opened = true
      this.client = {}
    },
    save() {
      this.loading_save = true;
      this.$store.state.server.request('client/create', this.client, (data) => {
        this.loading_save = false
        this.opened = false
        this.$eventBus.$emit('openClient', data.response);
        this.$root.notify('Клиент добавлен', 'success')
      }, (data) => {
        if (data && data.error) {
          this.$root.notify(data.error, 'error');
          if (data.client) {
            this.opened = false;
            this.$eventBus.$emit('openClient', data.client);
          }
        }
        this.loading_save = false
      })
    }
  },
}
</script>

<style scoped>

</style>
